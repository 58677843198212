import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { Layout, Block } from '../components'

import { container, padding, wrapper, bgImage, headingText, linkText, heading } from '../styles/global'
import { parseACF } from '../utils';
import { lightGrey } from '../styles/colors';
import { media } from '../styles/utils';

const About = (props) => {
    let data = parseACF(useStaticQuery(query), 'allWordpressInfopages', true);
    
    if (props.previewData) {
        data = JSON.parse(props.previewData.acf_json) 
    }

    const renderIntroduction = () => {
        if (!data.display_introduction) return;

        return (
            <Introduction>
                <Heading>{data.introduction_heading}</Heading>

                <Info>
                    <Description
                        dangerouslySetInnerHTML={{__html: data.introduction_text}}  
                    />
                </Info>
            </Introduction>
        )
    }

    const renderSlider = () => {
        if (!data.display_slider) return;

        return (
            <Block
                layout={'image_slider'}
                slides={data.image_slider}
                controls
            />
        )
    }

    const renderTeam = () => {
        if (!data.team || !data.team.length) return;

        const members = data.team.map((item, i) => {
            if (!item.display_person) return;

            return (
                <TeamMember 
                    key={i}
                >
                    <Heading
                        dangerouslySetInnerHTML={{__html: `
                            ${item.name}<br>
                            ${item.role}
                        `}}
                    />

                    <Info>
                        {item.image && (
                            <Image
                                key={item.image.id}
                                src={item.image.sizes.large}
                            >
                                {(src, loading) => {
                                    return (
                                        <BGImage 
                                            image={src} 
                                            style={{opacity: loading ? 0 : 1}}  
                                        />
                                    )
                                }}
                            </Image> 
                        )}

                        <Description
                            dangerouslySetInnerHTML={{__html: item.short_description}}
                        />
                    </Info>
                </TeamMember>
            )
        })

        return (
            <Team>
                <Heading>{data.our_team_title}</Heading>
                <Grid>
                    {members}
                </Grid>
            </Team>
        )
    }

    const renderTextHero = () => {
        if (!data.display_text_hero) return;

        return (
            <TextHero
                dangerouslySetInnerHTML={{__html: data.text_hero}}
            />
        )
    }

    return (
		<Layout
            meta={data && data.seo}
            callToAction={true}
        >
			<Wrapper>
                <Container>
                    {renderIntroduction()}
                    {renderSlider()}
                    {renderTeam()}
                    {renderTextHero()}
                </Container>
            </Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const InternalLink = styled(Link)`
    ${linkText}
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    ${wrapper}
    width: 100%;
`

const Container = styled.div`
	${container}
	${padding}
    flex-direction: column;
`

// Introduction

const Introduction = styled.div`
    ${headingText}

    ${media.phone`
        margin-bottom: 100px;

        ${Info} {
            margin-top: 120px;
        }
    `}
`

// Team

const Team = styled.div`
    margin-top: 110px;

    ${Heading} {
        ${heading}
    }

    ${media.phone`
        margin-top: 10px;
    `}
`

const Grid = styled.div`
    margin-top: 60px;
`

const TeamMember = styled.div`
    display: flex;
    margin-bottom: 80px;

    ${Heading},
    ${Info} {
        flex: 1 0 50%;
    }

    ${Heading} {
        margin-top: 80px;
    }

    ${Info} {
        display: flex;
        flex-direction: column;

        ${Description} {
            margin-top: 32px;

            &, p {
                font-size: 18px;
                color: ${lightGrey}
            }
        }

        ${BGImage} {
            height: 458px;
        }
    }

    ${media.phone`
        flex-direction: column;

        ${Heading} {
            margin-top: 0;
        }

        ${Info} {
            margin-top: 30px;
            
            ${BGImage} {
                height: 232px;
            }
        }
    `}
`

// Text Hero

const TextHero = styled.div`
    font-size: 36px;
    text-align: center;
    max-width: 1000px;
    padding: 0 80px;
    margin: 180px 0 260px;
    align-self: center;

    ${media.phone`
        font-size: 24px;
        margin: 48px 0 200px;
        padding: 0 20px;
    `}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "about" } }) {
            nodes {
                acf_json
            }
        }
    }
`


export default About

